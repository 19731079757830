import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

// import variables from '../../sass/variables.module.scss'

const StyledCard = styled.section`
  .card {
    &:not(:last-child){
      margin-bottom: 35px;
    }
  }
  .title {
    @media (min-width: 960px){
      min-height: 60px;
      margin-bottom: 25px;
    }
    @media (max-width: 767px){
      margin-bottom: 15px;
    }
  }
` 

const Card = ({className, id}) => {
 
  const data = useStaticQuery(graphql`
    {
      privateWealthManagement: file(relativePath: {eq: "Home/Private-Wealth-Management.svg"}) {
        publicURL
      } 
      privateEquity: file(relativePath: {eq: "Home/Private-Equity-Real-Estate.svg"}) {
        publicURL
      }
      advancedTaxPlanning: file(relativePath: {eq: "Home/Advanced-Tax-Planning.svg"}) {
        publicURL
      }
      wealthPreservation: file(relativePath: {eq: "Home/Wealth-Preservation.svg"}) {
        publicURL
      } 
    }
  `)
 
  return (
    <StyledCard id={id} className={className}>
        <div className="grid-container">
          <header className="text-center margin-bottom-xlarge">
              <p className="decorative-text">Our Strategies</p>
              <h1>Strategies for Wealth Generation and Preservation</h1>
          </header>
          <div className="grid-x grid-margin-x grid-margin-y text-center margin-bottom-xsmall">
              <div className="cell small-12 medium-6 large-3 card">
                  <div className="icon-wrapper margin-bottom-medium">
                    <img src={data.privateWealthManagement.publicURL} alt="Private Wealth Management icon" />
                  </div>
                  <p className="body-large title">Private Wealth Management </p>
                  <p className="margin-zero">Different by design. Portfolio management that goes beyond conventional investment philosophy by diversifying with alternative asset classes.</p>
              </div>
              <div className="cell small-12 medium-6 large-3 card">
                  <div className="icon-wrapper margin-bottom-medium">
                    <img src={data.privateEquity.publicURL} alt="Private Equity & Real Estate icon" />
                  </div>
                  <p className="body-large title">Private Equity & Real Estate</p>
                  <p className="margin-zero">An institutional approach. We create differentiated value with a unique expertise in private equity and private real estate offerings.</p>
              </div>
              <div className="cell small-12 medium-6 large-3 card">
                  <div className="icon-wrapper margin-bottom-medium">
                    <img src={data.advancedTaxPlanning.publicURL} alt="Advanced Tax Planning icon" />
                  </div>
                  <p className="body-large title">Advanced Tax Planning</p>
                  <p className="margin-zero">Focused on the net. A prudent plan must address all relevant tax implications- income, capital gains, and gift tax considerations- with the goal of achieving the best net investment result.*</p>
              </div>
              <div className="cell small-12 medium-6 large-3 card">
                  <div className="icon-wrapper margin-bottom-medium">
                    <img src={data.wealthPreservation.publicURL} alt="Wealth Preservation icon" />
                  </div>
                  <p className="body-large title">Wealth Preservation</p>
                  <p className="margin-zero">Being a good steward. An abundant life can be passed on through creative estate planning techniques to create generational wealth intended for future generations.*</p>
              </div>                         
          </div>
          <p className="disclaimer text-center body-small max-width-small margin-auto hide-for-small-only opacity-50"><i>Rancho Private Wealth does not provide tax or legal advice. You should contact your tax advisor and/or attorney before making any decisions with tax or legal implications.</i></p>
        </div>
    </StyledCard>
  )  
}

export default Card;
