import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import variables from '../../sass/variables.module.scss'

const Hero = ({title, sources, children, className}) => (

    <BackgroundImage className={className} fixed={sources}>
    <div className="grid-container">
        <header>
            {children}
        </header>
    </div>
    </BackgroundImage>

)

const StyledHero = styled(Hero)`
    display: flex !important;
    max-height: 640px;
    height: 80vw;
    width: 100%;
    align-items: flex-end;
    padding-bottom: 140px;
    @media (max-width: 767px) {
        max-height: 560px;
        height: 150vw;
        padding-bottom: 50px;
    }
    .headline {
        font-family: ${variables.fontPrimary};
        font-size: 50px; 
        line-height: 58px;
        color: ${variables.colorWhite};
        margin-bottom: 22px;
        @media (max-width: 767px) {
            font-size: 42px;
            line-height: 48px;
            margin-bottom: 30px;
        }
    }
    .subheadline {
        font-family: ${variables.fontSecondary};
        font-size: 20px; 
        line-height: 30px;
        color: ${variables.colorWhite};
        max-width: 590px;
        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 29px;
        }
    }
`
export default StyledHero
