import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"

import variables from '../../sass/variables.module.scss'

const Banner = ({imageDesktop, imageMobile, children, className, id, position, height}) => (

    <BackgroundImage id={id} className={className} fixed={imageDesktop} minHeight={height} style={{backgroundPosition: position}}>
        <Img className='show-for-small-only mobile-banner' fluid={imageMobile} />
        <div className="grid-container relative">
            {children}
        </div>
    </BackgroundImage>

)

const StyledBanner = styled(Banner)`
    display: flex !important;
    align-items: center;
    * {
      color: ${variables.colorWhite};
    }
    @media (min-width: 768px) {
        min-height: ${({ height }) => { return height }};
    }
    @media (max-width: 767px) {
        padding-bottom: 30px;
        flex-wrap: wrap;
        background-image: none;
        background-color: ${variables.colorGray800};
        &:before,
        &:after {
            display: none !important;
        }
        .mobile-banner {
            width: 100%;
            margin-bottom: -17vw;         
        }
        .heading {
            margin-bottom: 30px;
        }
    }
`
export default StyledBanner
