import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import variables from '../../sass/variables.module.scss'

const StyledCard = styled.section`
  .card {
    background-color: ${variables.colorWhite};
    @media (min-width: 768px){
      border-radius: 9px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14);
    }
  }
  .image {
    padding: 40px;
    background-color: #DCDFE7;
    @media (min-width: 768px){
      border-radius: 10px 10px 0 0;
    }
    @media (max-width: 767px){
      padding: 32px;
    }
  }
  .card-section {
    padding: 35px 40px;
    @media (max-width: 767px){
      padding: 25px 0px 15px;
    }
  }
` 

const Card = ({className, id}) => {
 
  const data = useStaticQuery(graphql`
    {
      defineObjective: file(relativePath: {eq: "Home/Define-the-Objective.svg"}) {
        publicURL
      }
      defineStrategy: file(relativePath: {eq: "Home/Develop-the-Strategy.svg"}) {
        publicURL
      }
      executeSolution: file(relativePath: {eq: "Home/Execute-the-Solution.svg"}) {
        publicURL
      }
    }
  `)
 
  return (
    <StyledCard id={id} className={className}>
        <div className="grid-container">
          <header className="text-center margin-bottom-xlarge">
              <p className="decorative-text">Our Philosophy</p>
              <h2>A Higher Standard for the Uncommon Investor</h2>
          </header>
          <div className="grid-x grid-margin-x grid-margin-y align-center text-center">
              <div className="cell small-12 medium-6 large-4 card">
                <div className="image">
                  <img src={data.defineObjective.publicURL} alt="Define the Objective icon" />
                </div>
                <div className="card-section">
                  <p className="body-large margin-bottom-small">Define the Objective</p>
                  <p className="margin-zero">We need to fully understand the symptoms before we can write the script. Our standard of care model calls us to have a clearly identified and communicated objective prior to developing a strategy.</p>
                </div>
              </div>
              <div className="cell small-12 medium-6 large-4 card">
                <div className="image">
                  <img src={data.defineStrategy.publicURL} alt="Develop the Strategy icon" />
                </div>
                <div className="card-section">
                  <p className="body-large margin-bottom-small">Develop the Strategy</p>
                  <p className="margin-zero">Our ability to draft a custom blueprint is what sets us apart. From our custom designs and unparalleled sourcing platform, we set out to deliver a unique solution for every client need. </p>
                </div>
              </div>
              <div className="cell small-12 medium-6 large-4 card">
                <div className="image">
                  <img src={data.executeSolution.publicURL} alt="Execute the Solution icon" />
                </div>
                <div className="card-section">
                  <p className="body-large margin-bottom-small">Execute the Solution</p>
                  <p className="margin-zero">Having a plan is important, but executing the plan is vital. Our team was built around the idea of relentless execution and being accountable to our clients.</p>
                </div>
              </div>               
          </div>
        </div>
    </StyledCard>
  )  
}

export default Card;
