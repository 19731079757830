import React from "react";
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroHome from "../components/Hero/HeroHome"
import BannerImage from "../components/Banner/BannerImage"
import CardStrategies from "../components/Card/CardStrategies"
import CardPhilosophy from "../components/Card/CardPhilosophy"
import CardSolutions from "../components/Card/CardSolutions"
import ButtonSolid from '../components/Button/ButtonSolid'

export const IndexPage = ({data }) => {

  const heroImages = [
    data.heroDesktop.childImageSharp.fixed,
    {
    ...data.heroMobile.childImageSharp.fixed,
    media: `(max-width: 767px)`,
    },
  ]

  const aboutBannerDesktop = [
    data.aboutBannerDesktop.childImageSharp.fixed,
  ] 

  const aboutBannerMobile = [
    {
    ...data.aboutBannerMobile.childImageSharp.fluid,
    media: `(max-width: 767px)`,
    },
  ]   
  
  const teamBannerDesktop = [
    data.teamBannerDesktop.childImageSharp.fixed,
  ] 
  
  const teamBannerMobile = [
    {
    ...data.teamBannerMobile.childImageSharp.fluid,
    media: `(max-width: 767px)`,
    },
  ]   

  return (
    <Layout>
      <SEO
        title="Rancho Private Wealth: San Diego Wealth Management Firm"
        description="Rancho Private Wealth is a San Diego private wealth management firm. We help families, private businesses, & real estate investors locally & nationwide."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <HeroHome sources={heroImages}>
          <p className="headline">How Smart Money Invests.</p>
          <p className="subheadline">We are keenly focused on delivering a simplified experience with exclusive investment strategies designed to help you achieve your life goals.</p>
          <ButtonSolid className="mobile-button-full" as="button" data="modal-contact" text="Schedule Consultation"/>
      </HeroHome>

      <CardStrategies id="strategies" className="wrapper" />  

      <BannerImage id="about" imageDesktop={aboutBannerDesktop} imageMobile={aboutBannerMobile} position="center" height="580px">
        <div className="grid-x">
          <div className="cell small-12 medium-6 medium-offset-6">
            <header>
              <p className="decorative-text">About Us</p>
              <h2 className="heading heading-three">Experience a Simplified Financial Life</h2>
              <p>Rancho Private Wealth creates custom private wealth plans for families, business owners, and real estate investors with a focus on tax planning strategies and income generation. Our roots began in 2008 with Advanced Wealth Planning, and we’ve since grown to offer unique and effective wealth planning services. We’ll help you enjoy the same level of access and experience as large financial institutions and the extremely wealthy, but with a more boutique feel.*</p>
              <p className="disclaimer body-xsmall opacity-80"><i>Rancho Private Wealth does not provide tax or legal advice. You should contact your tax advisor and/or attorney before making any decisions with tax or legal implications.</i></p>
            </header>
          </div>
        </div>
      </BannerImage>      

      <CardPhilosophy id="philosophy" className="wrapper" />

      <BannerImage id="team" imageDesktop={teamBannerDesktop} imageMobile={teamBannerMobile} position="top" height="540px">
        <div className="grid-x">
          <div className="cell small-12 medium-5">
            <header>
              <p className="decorative-text">Our Team</p>
              <h2 className="heading">Experience is Everything </h2>
              <p>We have assembled a team of experts that are passionate about connecting our clients with our alternative investment platform solutions and delivering an exceptional standard of care. </p>
            </header>
          </div>
        </div>
      </BannerImage>

      <CardSolutions id="solutions" className="wrapper" />

    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
{
  openGraphImage: file(relativePath: {eq: "OpenGraph/Home-Open-Graph-Facebook.jpg"}) {
    publicURL
  }
  twitterOpenGraphImage: file(relativePath: {eq: "OpenGraph/Home-Open-Graph-Twitter.jpg"}) {
    publicURL
  }    
  heroDesktop: file(relativePath: {eq: "Home/Hero-Desktop.jpg"}) {
    childImageSharp {
      fixed(width: 2560, height: 1280, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  heroMobile: file(relativePath: {eq: "Home/Hero-Mobile.jpg"}) {
    childImageSharp {
      fixed(width: 750, height: 1120, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  } 
  aboutBannerDesktop: file(relativePath: {eq: "Home/About-Us.jpg"}) {
    childImageSharp {
      fixed(width: 2560, height: 1080, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  aboutBannerMobile: file(relativePath: {eq: "Home/About-Us-Mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 750, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  teamBannerDesktop: file(relativePath: {eq: "Home/Our-Team.jpg"}) {
    childImageSharp {
      fixed(width: 2560, height: 1080, quality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  teamBannerMobile: file(relativePath: {eq: "Home/Our-Team-Mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 750, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }   
}
`
