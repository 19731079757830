import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

// import variables from '../../sass/variables.module.scss'

const StyledCard = styled.section`
    .icon {
        margin-right: 15px;
    }
    .card {
        &:not(:last-child){
            @media (max-width: 767px){
                margin-bottom: 30px;
            }                
        }        
    }
` 

const Card = ({className, id}) => {
 
  const data = useStaticQuery(graphql`
    {
      privateRealEstate: file(relativePath: {eq: "Home/Private-Real-Estate.svg"}) {
        publicURL
      }
      dstExchanges: file(relativePath: {eq: "Home/DST-1031-Exchanges.svg"}) {
        publicURL
      }
      privateEquity: file(relativePath: {eq: "Home/Private-Equity.svg"}) {
        publicURL
      }
      opportunityZones: file(relativePath: {eq: "Home/Opportunity-Zones.svg"}) {
        publicURL
      }
      privateCredit: file(relativePath: {eq: "Home/Private-Credit.svg"}) {
        publicURL
      }
      managedMoney: file(relativePath: {eq: "Home/Managed-Money.svg"}) {
        publicURL
      } 
    }
  `)
 
  return (
    <StyledCard id={id} className={className}>
        <div className="grid-container">
            <header className="text-center mobile-text-align-initial margin-bottom-xlarge">
                <p className="decorative-text">Our Solutions</p>
                <h2>Specific Solutions for Custom Strategies</h2>
            </header>
            <div className="grid-x grid-margin-x grid-margin-y margin-bottom-medium">
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.privateRealEstate.publicURL} alt="Private Real Estate icon" />
                        <div>
                            <p className="body-large margin-bottom-small">Private Real Estate</p>
                            <p className="margin-zero">We will listen to you, learn about your needs, and work closely with you from beginning to end.</p>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.opportunityZones.publicURL} alt="Opportunity Zones icon" />
                        <div>
                            <p className="body-large margin-bottom-small">Opportunity Zones</p>
                            <p className="margin-zero">An investment into governmental designated areas that provides a tax- deferred solution for realized capital gains typically through the sale of an asset.</p>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.dstExchanges.publicURL} alt="DST 1031 Exchanges icon" />
                        <div>
                            <p className="body-large margin-bottom-small">DST 1031 Exchanges</p>
                            <p className="margin-zero">A tax-deferred approach to selling real estate investments into a Delaware Statutory Trust private placement offering.</p>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.privateCredit.publicURL} alt="Private Credit icon" />
                        <div>
                            <p className="body-large margin-bottom-small">Private Credit</p>
                            <p className="margin-zero">Private credit is non-bank lending where the debt is not issued or traded on the public markets. </p>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.privateEquity.publicURL} alt="Private Equity icon" />
                        <div>
                            <p className="body-large margin-bottom-small">Private Equity</p>
                            <p className="margin-zero">Exclusive access to the same private equity investments being utilized by large institutional investors.</p>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 card">
                    <div className="flex align-top">
                        <img className="icon" src={data.managedMoney.publicURL} alt="Managed Money icon" />
                        <div>
                            <p className="body-large margin-bottom-small">Managed Money</p>
                            <p className="margin-zero">Portfolio creation and management built around risk and time horizon with a keen focus on wealth preservation.</p>
                        </div>
                    </div>
                </div>                  
            </div>

            <p className="disclaimer body-small margin-zero opacity-50"><i>Alternative investments are subject to significant risks and therefore are not suitable for all investors. When considering alternative investments, you should consider various risks. These risks include, but are not limited to, lack of liquidity, loss of principal, limited transferability, conflicts of interest and fluctuating market conditions based upon a number of factors, which may include changes in interest rates, laws, operating expenses, insurance costs and tenant turnover. Investors should also understand all fees associated with a particular investment and how those fees could affect the overall performance of the investment. This is neither an offer to sell nor a solicitation of an offer to buy the securities described below. An offering is made only by the prospectus.</i></p>
        </div>
    </StyledCard>
  )  
}

export default Card;
